<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo Pago anticipado - Nuevo</strong>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" autocomplete="off" @submit.prevent="Validate">
              <b-row>

                <b-col md="6">
                  <b-form-group>
                    <label>Prestamo: </label>
                    <v-select @input="GetDataLoan" placeholder="Seleccione un prestamo" class="w-100" :filterable="false" label="full_name" v-model="ploan" @search="SearchLoans" :options="loans"></v-select>
                    <small v-if="errors.id_loan" class="form-text text-danger" >Seleccione un socio</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="N° Pago Anticipado:">
                    <b-form-input type="text" class="text-center" disabled v-model="prepaid.code"></b-form-input>
                    <small v-if="errors.number"  class="form-text text-danger" >Ingrese una numero</small>
                  </b-form-group>
                </b-col>

                 <b-col md="2">
                  <b-form-group label="Medio de Pago:">
                    <b-form-select  v-model="prepaid.payment_method" :options="payment_method"></b-form-select>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Saldo Capital:">
                    <b-form-input type="number"  disabled class="text-right" step="any" v-model="prepaid.principal_balance"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Dias Trans.:">
                    <b-form-input type="number" disabled class="text-center"  v-model="prepaid.days_passed"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Intéres:">
                    <b-form-input type="number" @change="CalculateAmount" class="text-right" step="any" v-model="prepaid.delinquent_amount"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Total:">
                    <b-form-input type="number" disabled class="text-right" step="any" v-model="prepaid.balance_total"></b-form-input>
                  </b-form-group>
                </b-col>


                <b-col md="2">
                  <b-form-group label="Monto a Cancelar:">
                    <b-form-input type="number" @change="CalculateAmount" class="text-right" step="any" v-model="prepaid.total"></b-form-input>
                    <small v-if="errors.total_prepaid"  class="form-text text-danger" >Ingrese una monto</small>
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group label="Doc. Adjuntos:">
                     <b-form-file
                      accept="application/pdf"
                      v-model="file"
                      :state="Boolean(file)"
                      @change="onFileChange"
                      placeholder="Selecciona un archivo"
                      drop-placeholder="Arrastre el archivo aqui"
                    ></b-form-file>
                    <small v-if="errors.file"  class="form-text text-danger" >Seleccione una archivo</small>
                  </b-form-group>
                </b-col>

             

                <b-col md="12">
                  <b-form-group label="Observaciones:">
                    <b-form-textarea rows="2" v-model="prepaid.observation"></b-form-textarea>
                  </b-form-group>
                </b-col>

                

              </b-row>
              <b-row v-if="parseFloat(loan.amount) > 0">


                <b-col md="12">
                <hr>
                </b-col>
                <b-col md="12" class="mb-2">
                  <strong>Nuevo Prestamo</strong> 
                </b-col>


               <b-col md="2">
                  <b-form-group label="N° Prestamo:">
                    <b-form-input type="text" class="text-center" disabled v-model="loan.number"></b-form-input>
                    <small v-if="errors.number"  class="form-text text-danger" >Ingrese una numero</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Monto:">
                    <b-form-input type="number" disabled class="text-right" step="any" v-model="loan.amount"></b-form-input>
                    <small v-if="errors.amount"  class="form-text text-danger" >Ingrese una monto</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="N° de Cuotas :">
                    <b-form-input type="number" @change="CalculateLoan" v-model="loan.dues"></b-form-input>
                    <small v-if="errors.dues" class="form-text text-danger" >Seleccione una cuota</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Fecha de Desembolso:">
                    <b-form-input @change="DayOfGrace" type="date" v-model="loan.disbursement_date"></b-form-input>
                    <small v-if="errors.disbursement_date" class="form-text text-danger">Seleccione una fecha</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Fecha de Pago:">
                    <b-form-input @change="DayOfGrace" type="date" :min="loan.disbursement_date" v-model="loan.payment_date"></b-form-input>
                    <small v-if="errors.payment_date" class="form-text text-danger">Seleccione una fecha</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="TEA:">
                    <b-form-select @change="CalculateLoan" v-model="loan.id_financial_data" :options="financial_data"></b-form-select>
                    <small v-if="errors.id_financial_data" class="form-text text-danger">Seleccione una tasa</small>
                  </b-form-group>
                </b-col>

                
                <b-col md="12">
                <hr>
                </b-col>

                <b-col class="" md="12">
                  <strong>Detalle de Prestamo</strong> 
                </b-col>
                <b-col md="12">
                    <div class="table-responsive mt-3">
                      <table class="table table-bordered">
                        <tbody>
                          <tr>
                            <td class="pl-1">Total Capital:</td>
                            <td class="text-right"><strong>{{loan.amount}}</strong></td>
                            <td class="pl-1">N° de Cuotas:</td>
                            <td class="text-right"><strong>{{loan.dues}}</strong></td>
                            <td class="pl-1">Total Interés:</td>
                            <td class="text-right"><strong>{{loan.interest_total}}</strong></td>
                            <td class="pl-1">Monto por Cuota:</td>
                            <td class="text-right"><strong>{{loan.final_installment}}</strong></td>
                            <td class="pl-1">Total a Pagar:</td>
                            <td class="text-right"><strong>{{loan.total}}</strong></td>
                            <td class="pl-1">Saldo Capital:</td>
                            <td class="text-right"><strong>{{loan.principal_balance}}</strong></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div class="table-responsive">
                      <table class="table table-hover table-bordered">
                        <thead>
                          <tr>
                            <th colspan="12" class="text-center">CRONOGRAMA DE PAGOS</th>
                          </tr>
                          <tr>
                            <th width="3%" class="text-center">#</th>
                            <th width="8%" class="text-center">Fecha</th>
                            <th width="8%" class="text-center">Amort.</th>
                            <th width="8%" class="text-center">Interés</th>
                            <th width="8%" class="text-center">Int. Difer.</th>
                            <th width="8%" class="text-center">Seg. Desg.</th>
                            <th width="8%" class="text-center">Seg. Mult.</th>
                            <th width="8%" class="text-center">ITF</th>
                            <th width="10%" class="text-center">Saldo Cap.</th>
                            <th width="10%" class="text-center">Cuota Final</th>
                            <th width="8%" class="text-center">Dias</th>
                            <th width="8%" class="text-center">Dias Acc.</th>
                          </tr>
                        </thead>
                        <tbody v-for="(item, it) in loan.loan_detail" :key="it">
                          <tr>
                            <td class="text-center">{{item.installment_number}}</td>
                            <td class="text-center">{{item.expiration_date}}</td>
                            <td class="text-right">{{item.amortization}}</td>
                            <td class="text-right">{{item.interest}}</td>
                            <td class="text-right">{{item.deferred_interest}}</td>
                            <td class="text-right">{{item.insurance_lien}}</td>
                            <td class="text-right">{{item.multi_insurance}}</td>
                            <td class="text-right">{{item.itf}}</td>
                            <td class="text-right">{{item.principal_balance}}</td>
                            
                            <td class="text-right">{{item.fixed_fee}}</td>
                            <td class="text-center">{{item.days}}</td>
                            <td class="text-center">{{item.accumulated_days}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                </b-col>

              </b-row>
              <b-row>

                <b-col md="5"></b-col>
                <b-col md="2">
                  <b-button type="submit" class="form-control" variant="primary" ><i class="fas fa-save"></i> Guardar (F4)</b-button>
                </b-col>
                
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>


    <ModalClients />
    <LoadingComponent :is-visible="isLoading"/>
    <Keypress key-event="keyup" :key-code="115" @success="Validate" />
  </div>
</template>

<script>

import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";


const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");

import { mapState,mapMutations,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";
// components
import ModalClients from './../components/ModalClient'
import LoadingComponent from './../pages/Loading'
export default {
  name: "UsuarioAdd",
  components:{
      vSelect,
      ModalClients,
      Keypress: () => import('vue-keypress'),
      LoadingComponent,
  },
  data() {
    return {
      isLoading: false,
      module: 'Prepaid',
      role: 2,
      prepaid: {
          id_prepaid : '',
          id_loan: '',
          id_user:'',
          id_new_loan: '',
          code:'',
          payment_date:moment(new Date()).local().format("YYYY-MM-DD"),
          payment_method:'008',
          observation: '',
          file: '',
          principal_balance: '',
          days_passed: '',
          delinquent_amount: '',
          balance_total: '',
          total: '',
          state:'1',
      },
      loan: {
          id_loan: '',
          id_partner: '',
          id_user_create: '',
          id_user_validate: '',
          id_financial_data: '',
          number: '',
          amount: '0.00',
          dues: '',
          disbursement_date: moment(new Date()).local().format("YYYY-MM-DD"),
          days_of_grace: 0,
          payment_date: moment(new Date()).local().format("YYYY-MM-DD"),
          interest_total: '0.00',
          deferred_interest: '0.00',
          total: '0.00',
          final_installment: '0.00',
          principal_balance: '0.00',
          file: '',
          observation: '',
          guarantor1: '',
          guarantor2: '',
          id_check: '',
          state: '1',
          loan_detail:[],
      },
      loans: [],
      ploan:null,

      guarantors1: [],
      guarantor1:null,
      guarantors2: [],
      guarantor2:null,

       payment_method: [
        {value :"008", text :'EFECTIVO'},
        {value :"007", text :'CHEQUE'},
        {value :"001", text :'DEPÓSITO EN CUENTA'},
        {value :"003", text :'TRANSFERENCIA DE FONDOS'},
      ],

      file:null,
      financial_data:[],
      dues:[
        {value:'',text:'Seleccion una cuota'},
        {value:'6',text:'6 Meses'},
        {value:'12',text:'12 Meses'},
        {value:'18',text:'18 Meses'},
        {value:'24',text:'24 Meses'},
        {value:'30',text:'30 Meses'},
        {value:'36',text:'36 Meses'},
        {value:'42',text:'42 Meses'},
        {value:'48',text:'48 Meses'},
        {value:'54',text:'54 Meses'},
        {value:'60',text:'60 Meses'},
        {value:'66',text:'66 Meses'},
        {value:'72',text:'72 Meses'},
        {value:'78',text:'78 Meses'},
        {value:'84',text:'85 Meses'},
        {value:'90',text:'90 Meses'},

      ],

      //errors
      errors: {
        id_loan :false,
        total_prepaid: false,
        id_partner: false,
        id_financial_data: false,
        number: false,
        amount: false,
        dues: false,
        disbursement_date: false,
        payment_date: false,
        interest_total: false,
        deferred_interest: false,
        total: false,
        final_installment: false,
        principal_balance: false,
        file: false,
        guarantor1: false,
        guarantor2: false,
      },
      validate: false,
    };
  },
  mounted() {
    this.GetCorrelativeLoan();
    this.GetCorrelativePrepaid();
    this.ListFinancialData();
  },
  methods: {
    GetDataLoan,
    CalculateAmount,



    ListFinancialData,
    GetCorrelativeLoan,
    GetCorrelativePrepaid,
    SearchLoans,

    CalculateLoan,
    DayOfGrace,

    AddPrepaid,
    Validate,
    onFileChange,

    DataPrint,
    Print,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
  },
};

function GetDataLoan() {

  if (this.ploan == null) {
    this.prepaid.principal_balance = '0.00';
    this.prepaid.days_passed = '0.00';
    this.prepaid.delinquent_amount = '0.00';
    this.prepaid.balance_total = '0.00';
    this.prepaid.total = '0.00';
    this.loan.amount = '0.00';
    return false;
  }

  this.prepaid.principal_balance = this.ploan.principal_balance;
  this.prepaid.days_passed = this.ploan.days_passed;
  this.prepaid.delinquent_amount = this.ploan.delinquent_amount;
  this.prepaid.balance_total = this.ploan.balance_total;
  this.prepaid.total = this.ploan.balance_total;
  // this.prepaid.total = '0.00';
  this.loan.amount = 0;

  this.CalculateAmount();

}

function CalculateAmount() {
  if (this.ploan == null) {
    this.prepaid.total = '0.00';
    this.loan.amount = '0.00';
    return false;
  }

  this.prepaid.delinquent_amount = this.prepaid.delinquent_amount.length == 0 ? 0 :this.prepaid.delinquent_amount;
  if (0 > parseFloat(this.prepaid.delinquent_amount)) {
    this.prepaid.delinquent_amount = 0;
  }
  this.prepaid.balance_total = parseFloat(this.prepaid.principal_balance) + parseFloat(this.prepaid.delinquent_amount);
  
  if (parseFloat(this.prepaid.total) > parseFloat(this.prepaid.balance_total)) {
    this.prepaid.total = this.prepaid.balance_total;
  }

  this.loan.amount = parseFloat(this.prepaid.balance_total) - parseFloat(this.prepaid.total)

  this.prepaid.delinquent_amount = parseFloat(this.prepaid.delinquent_amount).toFixed(2);
  this.prepaid.balance_total = parseFloat(this.prepaid.balance_total).toFixed(2);
  this.prepaid.total = parseFloat(this.prepaid.total).toFixed(2);
  this.loan.amount = parseFloat(this.loan.amount).toFixed(2);

  this.CalculateLoan();
}

function DayOfGrace() {
  var date1 = moment(this.loan.disbursement_date);
  var date2 = moment(this.loan.payment_date);
  this.loan.days_of_grace = date2.diff(date1, 'days');

  if (parseFloat(this.loan.days_of_grace) < 0) {
    this.loan.payment_date = this.loan.disbursement_date;
    this.DayOfGrace();
  }

  this.CalculateLoan();
}

function ListFinancialData() {
  let me = this;
  let url = me.url_base + "financial-data/list-actives";
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: 2, },
  })
  .then(function (response) {
    if (response.data.status == 200) {
      me.financial_data = [];
      for (let index = 0; index < response.data.result.length; index++) {
        const element = response.data.result[index];
        me.financial_data.push({
          value:element.id_financial_data, text:element.tea
        });

        if (element.default == 1) {
          me.loan.id_financial_data = element.id_financial_data;
        }
      }
    }else{
      me.financial_data = [];
      me.loan.id_financial_data = '';
    }
  })
}

function SearchLoans(search, loading) {
  
   let me = this;
    let url = this.url_base + "prepaid/list-prepaid/" + search;
    if (search !== "") {
      loading(true);
      axios({
        method: "GET",
        url: url,
        headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: 2, },
      }).then(function (response) {
            me.loans = response.data;
            loading(false);
      })
    }
}


function GetCorrelativeLoan() {
  let me = this;
  let url = me.url_base + "get-correlative/Loan";
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: 2, },
  })
  .then(function (response) {
    if (response.data.status == 200) {
      me.loan.number = response.data.result.number;
    }else{
      me.loan.number = '';
    }
  })
}

function GetCorrelativePrepaid() {
  let me = this;
  let url = me.url_base + "get-correlative/Prepaid";
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: 2, },
  })
  .then(function (response) {
    if (response.data.status == 200) {
      me.prepaid.code = response.data.result.number;
    }else{
      me.prepaid.code = '';
    }
  })
}

function onFileChange(e) {
  this.prepaid.file = e.target.files[0];
}


function CalculateLoan() {

  this.errors.amount = this.loan.amount.length == 0 || parseFloat(this.loan.amount) == 0 ? true : false;
  this.errors.dues = this.loan.dues.length == 0 ? true : false;
  this.errors.disbursement_date = this.loan.disbursement_date.length == 0 ? true : false;
  this.errors.payment_date = this.loan.payment_date.length == 0 ? true : false;

  if (this.errors.amount) {  return false;}else{ this.validate_add = false; }
  if (this.errors.dues) {  return false;}else{ this.validate_add = false; }
  if (this.errors.disbursement_date) {  return false;}else{ this.validate_add = false; }
  if (this.errors.payment_date) {  return false;}else{ this.validate_add = false; }

  let me = this;
  let url = me.url_base + "loans/calculate-loan";
  let data = me.loan;
  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: 2, },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.loan.loan_detail = response.data.result.loan_detail;
        me.loan.interest_total = response.data.result.loan_data.interest_total;
        me.loan.final_installment = response.data.result.loan_data.final_installment;
        me.loan.total = response.data.result.loan_data.total;
        me.loan.principal_balance = response.data.result.loan_data.principal_balance;

        // Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}



function AddPrepaid() {
  let me = this;
  let data = new FormData();

  data.append("id_loan", this.ploan.id);
  data.append("id_user", this.user.id_user);
  data.append("code", this.prepaid.code);
  data.append("payment_method", this.prepaid.payment_method);
  data.append("mpayment_date", this.prepaid.payment_date);
  data.append("observation", this.prepaid.observation);
  data.append("file", this.prepaid.file);
  data.append("mprincipal_balance", this.prepaid.principal_balance);
  data.append("mdays_passed", this.prepaid.days_passed);
  data.append("mdelinquent_amount", this.prepaid.delinquent_amount);
  data.append("mbalance_total", this.prepaid.balance_total);
  data.append("mtotal", this.prepaid.total);
  data.append("state", this.prepaid.state);

  data.append("id_financial_data", this.loan.id_financial_data);
  data.append("number", this.loan.number);
  data.append("amount", this.loan.amount);
  data.append("dues", this.loan.dues);
  data.append("disbursement_date", this.loan.disbursement_date);
  data.append("days_of_grace", this.loan.days_of_grace);
  data.append("payment_date", this.loan.payment_date);
  data.append("interest_total", this.loan.interest_total);
  data.append("deferred_interest", this.loan.deferred_interest);
  data.append("total", this.loan.total);
  data.append("final_installment", this.loan.final_installment);
  data.append("principal_balance", this.loan.principal_balance);
  data.append("loan_detail", JSON.stringify(this.loan.loan_detail));

  let url = me.url_base + "prepaid/add";

  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: 2, },
  })
    .then(function (response) {
      if (response.data.status == 201) {
          
          me.prepaid.id_prepaid  =  '';
          me.prepaid.id_loan =  '';
          me.prepaid.id_user = '';
          me.prepaid.id_new_loan =  '';
          me.prepaid.code = '';
          me.prepaid.payment_date = moment(new Date()).local().format("YYYY-MM-DD");
          me.prepaid.payment_method = '008';
          me.prepaid.observation =  '';
          me.prepaid.file =  '';
          me.prepaid.total =  '';
          me.prepaid.state = '1';
     
 
          me.loan.id_loan= '';
          me.loan.id_partner= '';
          me.loan.id_user_create= '';
          me.loan.id_user_validate= '';
          me.loan.id_financial_data= '';
          me.loan.number= '';
          me.loan.amount= '0.00';
          me.loan.dues= '';
          me.loan.disbursement_date= moment(new Date()).local().format("YYYY-MM-DD");
          me.loan.days_of_grace= 0;
          me.loan.payment_date= moment(new Date()).local().format("YYYY-MM-DD");
          me.loan.interest_total= '0.00';
          me.loan.deferred_interest= '0.00';
          me.loan.total= '0.00';
          me.loan.final_installment= '0.00';
          me.loan.principal_balance= '0.00';
          me.loan.file= '';
          me.loan.observation= '';
          me.loan.guarantor1= '';
          me.loan.guarantor2= '';
          me.loan.id_check= '';
          me.loan.state= '1';
          me.loan.loan_detail=[];
    

          me.file = null;
          me.ploan = null;
          me.guarantor1 = null;
          me.guarantor2 = null;

          me.ListFinancialData();
          me.GetCorrelativeLoan();
          me.GetCorrelativePrepaid();
          me.DataPrint(response.data.result.id_prepaid);
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}

function Validate() {

  this.errors.id_loan = this.ploan == null ? true : false;
  this.errors.total_prepaid =  parseFloat(this.prepaid.total) == 0 ? true : false;

  if (parseFloat(this.loan.amount) > 0) {
      this.errors.id_financial_data = this.loan.id_financial_data.length == 0 ? true : false;
      this.errors.number = this.loan.number.length == 0 ? true : false;
      this.errors.amount = this.loan.amount.length == 0 ? true : false;
      this.errors.dues = this.loan.dues.length == 0 ? true : false;
      this.errors.disbursement_date = this.loan.disbursement_date.length == 0 ? true : false;
      this.errors.payment_date = this.loan.payment_date.length == 0 ? true : false;
      this.errors.interest_total = this.loan.interest_total.length == 0 ? true : false;
      this.errors.deferred_interest = this.loan.deferred_interest.length == 0 ? true : false;
      this.errors.total = parseFloat(this.loan.total) == 0 ? true : false;
      this.errors.final_installment = parseFloat(this.loan.final_installment) == 0 ? true : false;
      this.errors.principal_balance = parseFloat(this.loan.principal_balance) == 0 ? true : false;
  }else{
      this.errors.id_financial_data = false;
      this.errors.number = false;
      this.errors.amount = false;
      this.errors.dues = false;
      this.errors.disbursement_date = false;
      this.errors.payment_date = false;
      this.errors.interest_total = false;
      this.errors.deferred_interest = false;
      this.errors.total = false;
      this.errors.final_installment = false;
      this.errors.principal_balance = false;
  }

  if (this.errors.id_loan) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.total_prepaid) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.id_financial_data) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.number) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.amount) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.dues) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.disbursement_date) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.payment_date) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.interest_total) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.deferred_interest) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.total) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.final_installment) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.principal_balance) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }


  let me = this;
  Swal.fire({
    title: "Esta seguro de registrar el pago anticipado ?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.AddPrepaid();
    }
  });
}


function DataPrint(id_prepaid) {
  let me = this;
  let url = me.url_base + "prepaid/data-print/"+id_prepaid;
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
  .then(function (response) {
    if (response.data.status == 200) {
      me.Print(response.data.result)
    }
  })
  .catch((error) => {
    Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    me.isLoading = true;
  });
}


function Print(info) {
  let url = 'http://localhost/print/consumirapi.php';
  var data = new FormData(); 
  data.append("info",JSON.stringify(info)); 

  axios({
    method: "POST",
    url: url,
    data:data,
    headers: {
      "Content-Type": "application/json",
      "Accept":"*/*",
    },
  })
    .then(function (response) {
     
    })
    .catch((error) => {
      console.log(error);
    });
}
</script>
